@media all and (min-width: 480px) {
    .Register {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        flex-direction: column;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;
        padding: 60px 0;
    }

    .Register form {
        margin: 0 auto;
        max-width: 320px;
    }
}

.Register form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
}
