.App {
  text-align: center;
  horiz-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #2E4272;
  flex-wrap: nowrap;
  font-size: 15px;
  color: white;
  position: relative;
}

.App-link {
  color: #61dafb;
}

.header button {
  border: none;
  cursor: pointer;
  height: 24px;
  margin-right: 16px;
  opacity: 0.54;
  outline: none;
  overflow: hidden;
  text-indent: -10000px;
  transition: opacity 0.333s cubic-bezier(0, 0, 0.21, 1);
  width: 24px;
}