@media all and (min-width: 480px) {
    .Login {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        flex-direction: column;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;
        padding: 60px 0;
    }

    .Login form {
        margin: 0 auto;
        max-width: 80vw;
    }
}


.vert-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    flex-direction: column;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}